body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* The navbar is too condensed if it's smaller, and on my phone the page
       looks just fine if it's zoomed out so this fits on screen. */
    min-width: 450px;
}

/* When jumping to an element, set the top of the viewport this far above it */
:root {
    scroll-padding-top: 128px;
    scroll-behavior: smooth;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

#content-root {
    color: white;
    padding: 0px 2em 2em;
    min-height: calc(100vh - 225px);
}

#bigLogo {
    height: 300px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

a {
    color: aqua;
}

a:visited {
    color: royalblue;
}

#main-text {
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
}

h1, h2 {
    margin-block-end: 0.25em;
    border-bottom: 1px solid white;
    padding-bottom: 3px;
}

/* Spans which expand/collapse content upon being clicked */
.show-toggle {
    color: grey;
    cursor: pointer;
}
/* Since they act like buttons, they shouldn't be highlighted by rapid clicks */
.show-toggle::selection {
    background-color: unset;
}
