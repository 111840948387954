/* (Rule to set minimum width of <body> moved to index.css) */

.wikiPageBody :not(.math) > em, .wikiPageBody strong {
    color: #FF2;
}

@keyframes sectionColorFrames {
    0% {
        color: #FF4;
    }
    100% {
        color: white;
    }
}

.wikiPageBody .activeSection {
    animation-name: sectionColorFrames;
    animation-duration: 5s;
    animation-timing-function: ease-out;
}

.wikiPageBody .pageTitle {
    border-width: 2px;
}

.wikiPageBody .doubleFig {
    float: right;
    display: flex;
    flex-direction: row;
}

@media (max-width: 500px) {
    .wikiPageBody .doubleFig {
        flex-direction: column;
    }
}

.wikiPageBody .doubleFig figure {
    margin: 5px;
    margin-right: 1px;
    margin-left: 10px;
    width: 120px;
}

.wikiPageBody .singleFig figure {
    float: right;
    margin: 5px;
    margin-right: 1px;
    margin-left: 10px;
    width: 120px;
}

/*
Have a single image take up as much space as a doubleFig
(make sure it's inside a div.wikiPageSection.twoImage)
*/
.wikiPageBody .wideFig figure {
    float: right;
    margin: 5px;
    margin-right: 1px;
    margin-left: 10px;
    width: 250px;
}

.wikiPageBody figure img {
    width: 100%;
}

.wikiPageBody figure picture {
    width: 100%;
}

.wikiPageBody figure > figcaption {
    font-size: 0.8em;
}

.wikiPageBody p {
    margin-left: 2px;
}

.wikiPageSection {
    overflow: auto;
}

.wikiPageBody {
    /* Width of the fade-out effect for headers and <hr>'s */
    --fade-width: 5px;
}

.wikiPageBody h2 {
    margin-top: 0px;
    background-image: url("header_backg.png");
    background-repeat: repeat-x;
    background-size: contain;

    /* Make it fade out at each side */
    mask-image: linear-gradient(90deg, transparent, black var(--fade-width), black calc(100% - 2 * var(--fade-width)), transparent 100%);
    -webkit-mask-image: linear-gradient(90deg, transparent, black var(--fade-width), black calc(100% - 2 * var(--fade-width)), transparent 100%);

    /* Offset the text so it's not touched by the fade-out */
    padding-left: calc(1.5 * var(--fade-width));
    /* Fixes mask control point positions, otherwise left-padding breaks them */
    box-sizing: border-box;
}

.wikiPageBody hr {
    border: 1px solid white;
    border-bottom: 0px;
    margin-bottom: 0px;

    /* Make it fade out at each side */
    mask-image: linear-gradient(90deg, transparent, black var(--fade-width), black calc(100% - 2 * var(--fade-width)), transparent 100%);
    -webkit-mask-image: linear-gradient(90deg, transparent, black var(--fade-width), black calc(100% - 2 * var(--fade-width)), transparent 100%);
}

/*
A section leaving room for a single figure on the right.
*/
.wikiPageBody .oneImage > h2 {
    width: calc(100% - 130px);
}

/*
A section leaving room for two figures, or one wide figure, on the right.
*/
.wikiPageBody .twoImage > h2 {
    width: calc(100% - 260px);
}

@media (max-width: 500px) {
    .wikiPageBody .twoImage > h2 {
        width: calc(100% - 130px);
    }
}

/* TODO: This is a SICK NASTY hack which makes a single header on a single
page look slightly nicer on specific devices, and will break if I so much as
look at it funny.
If you're reading this, I promise I wouldn't do this if you hired me to work
for you. */
@media (max-width: 474px) {
    .wikiPageBody .tipsThingy h2:last-of-type {
        width: 100%;
    }
}

.wikiPageBody .tinyCursor {
    height: 1em;
    position: relative;
    top: 2px;
}

.wikiPageBody .tinyContainer {
    position: relative;
}

.wikiPageBody .tinyCombo {
    height: 2em;
    position: absolute;
    top: -3px;
    left: -2px;
}

.wikiPageBody .aLittleToTheRight {
    left: 2px !important;
}

.wikiPageBody blockquote {
    font-style: italic;
}

.wikiPageBody dialog {
    position: relative;
}

.wikiPageBody .hiddenTable {
    display: none;
}

.wikiPageBody .tableToggle {
    color: grey;
    cursor: pointer;
}

.wikiPageBody table {
    border: 1px solid white;
    border-collapse: collapse;
    margin-left: auto;
    margin-right: auto;
}

.wikiPageBody th, .wikiPageBody td {
    border: 1px solid white;
    padding: 0px 5px;
}

.wikiPageBody td > img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4px;
    margin-bottom: 4px;
}

.wikiPageBody th {
    text-align: center !important;
}

.wikiPageBody thead > tr {
    background-color: #222;
}

.wikiPageBody tr:nth-child(even) {
    background-color: #181818;
}

.wikiPageBody .junk-effects-table td:nth-last-child(3) {
    background-color: black;
}

.wikiPageBody span.math {
    color: #6F8;
}

.wikiPageBody .blockImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
}

.wikiPageBody .fig-w-480 figure {
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.wikiPageBody .fig-w-480 picture {
    display: block;
    max-width: fit-content;
    max-height: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.wikiPageBody .fig-w-480 picture > img {
    max-width: 480px;
}

.wikiPageBody .fig-w-250 figure {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.wikiPageBody .fig-w-250 figure img {
    max-width: 250px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.wikiPageBody .bordered {
    border: 1px solid white;
}

.wikiPageBody .bold-left-col tbody td:first-child {
    font-weight: 500;
}

.contentsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.contentsContainer > * {
    margin-left: auto;
    margin-right: auto;
    width: 100% !important;
    max-width: 225px;
    box-sizing: border-box;
    height: fit-content;
}

.pageContents ul {
    padding-left: 20px;
}

.wikiPageBody div.sourceCode {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    background-color: #181818;
    border: #888 solid 1px;
    border-radius: 3px;
}

.wikiPageBody div.sourceCode > pre {
    padding: 10px;
    margin: 0px;
}

.wikiPageBody pre.sourceCode {
    overflow: auto;
}

/* The table needs to be wider to fit all the text, but using this width makes
the list of 'Other Properties' vertical with one property per line */
.wikiPageBody .modding-abilities-table table {
    max-width: 360px;
}

.wikiRingFooter {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

hr.wikiRingSeparator {
    border: 1px solid white;
    border-bottom: 0px;

    --fade-width: 5px;
    /* Make it fade out at each side */
    mask-image: linear-gradient(90deg, transparent, black var(--fade-width), black calc(100% - 2 * var(--fade-width)), transparent 100%);
    -webkit-mask-image: linear-gradient(90deg, transparent, black var(--fade-width), black calc(100% - 2 * var(--fade-width)), transparent 100%);
}
