@keyframes logoLeftKF {
    0% {
        left: min(calc(-50vw - 216px), -1000px);
    }
    100% {
        left: calc(50% - 216px);
    }
}

@keyframes logoRightKF {
    0% {
        right: min(calc(-50vw - 216px), -1000px);
    }
    100% {
        right: calc(50% - 216px);
    }
}

.logoContainer {
    height: 240px;
    position: relative;
    margin-top: 16px;
    overflow: hidden;
    z-index: 0;
    cursor: pointer;
}

.logoContainer > .logoMaskedUpper, .logoContainer > .logoMaskedLower {
    position: absolute;
    height: 240px;
    -webkit-mask-image: url("img/title_mask.png");
    mask-image: url("img/title_mask.png");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;

    animation-duration: 0.3s;
    animation-delay: 0.3s;
    animation-timing-function: linear;
}

.logoContainer > .logoMaskedUpper {
    left: min(calc(-50vw - 216px), -1000px);
    animation-name: logoLeftKF;
}

.logoContainer > .logoMaskedLower {
    right: min(calc(-50vw - 216px), -1000px);
    /* Rotate the mask 180deg so the opposite lines are masked */
    transform: rotate(180deg);
    animation-name: logoRightKF;
}

.logoContainer > .logoMaskedLower > img {
    /* Rotate the logo 180deg to counteract the rotation above,
    so only the mask is rotated */
    transform: rotate(180deg);
}

@keyframes logoStatic {
    0% {
        opacity: 0.0;
    }
    100% {
        opacity: 1.0;
    }
}

.logoContainer > .logoUnmasked {
    position: relative;
    left: calc(50% - 216px);
    opacity: 0.0;
    animation-name: logoStatic;
    animation-duration: 0s;
    animation-delay: 0.6s;
    animation-fill-mode: forwards;
}
