#search-bar
{
    width: fit-content;
    height: 24px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: -2px;
    box-sizing: border-box;
}

#search-bar div
{
    height: 100%;
    box-sizing: border-box;
}

#search-bar div form
{
    height: 100%;
    box-sizing: border-box;
}

#search-bar div form input
{
    height: 100%;
    box-sizing: border-box;
}

#search-bar input:first-child
{
    background-color: black;
    color: white;
    border: 1px solid white;
    border-right: 0px;
    border-radius: 0px;
}

#search-bar input:last-child
{
    background-color: #222;
    color: white;
    border: 1px solid white;
    border-radius: 0px;
    cursor: pointer;
}

#search-bar input:last-child:hover
{
    color: #FF4;
    border-color: #FF4;
    background-color: #111;
}

.wikiSearchResults
{
    list-style-type: none;
    width: fit-content;
    padding: 0px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8px;
}

.wikiSearchResults li
{
    border-top: 1px solid white;
    mask-image: linear-gradient(90deg,
        transparent,
        black 5px,
        black calc(100% - 5px),
        transparent 100%
    );
    -webkit-mask-image: linear-gradient(90deg,
        transparent,
        black 5px,
        black calc(100% - 5px),
        transparent 100%
    );
}

.wikiSearchResults li:last-child
{
    border-bottom: 1px solid white;
}

.wikiSearchResults li a
{
    display: block;
    color: unset;
    text-decoration: none;
}

.wikiSearchResults li.wikiSearchItem a
{
    padding: 5px;
}

.wikiSearchResults li.wikiSectionMatchItem a:first-child
{
    /* No padding on the bottom so it's flush with section link */
    padding: 5px 5px 0px 5px;
}

.wikiSearchResults li.wikiSectionMatchItem a:last-child
{
    /* No padding on top so it's flush with page link */
    padding: 0px 5px 5px 5px;
}

.wikiSearchResults li a > div:first-child
{
    color: aqua;
    font-weight: bold;
}

.wikiSearchResults li a:visited > div:first-child
{
    color: royalblue;
}

.wikiSearchResults li a:hover > div:first-child
{
    color: #FF8;
}

.wikiSearchResults li a:focus > div:first-child
{
    color: #FF2;
}

.wikiSearchResults li a > div.resultReason
{
    font-style: italic;
    color: #CCC;
}

.wikiSearchResults li a > div.resultSection
{
    font-style: italic;
    font-weight: 500;
}
