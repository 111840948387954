#webringFooter {
    color: white;
    border: 0px;
    border-top: 1px solid white;
    display: flex;
    justify-content: center;
    padding: 5px;
}

#webringFooter > div {
    margin-left: 10px;
    margin-right: 10px;
    flex: 0 0 fit-content;
}

#webringFooter > div:first-child {
    flex: 1;
    text-align: right;
}

#webringFooter > div:last-child {
    flex: 1;
    text-align: left;
}
