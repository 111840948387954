#siteFooter {
    color: white;
    border: 0px;
    border-top: 2px solid white;
    align-content: center;
    margin-bottom: 10px;
}

#siteFooter > div {
    text-align: center;
}
