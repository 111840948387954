/* Surround the whole thing in a border, space it out from the navbar above */
.social-links {
    border: 1px solid white;
    margin-top: 1em;
}

/* Add padding for the main text, but omit top padding since the header area
   is above */
.social-links-show > .social-content {
    padding: 0px 1em 0.75em 1em;
}

/* If the "Hide" button was clicked, don't show the main content at all */
.social-links-hide > .social-content {
    display: none;
}

/* The top has a header and a button to hide/show the content. Display it as a
   grid where the header takes up all space that the button doesn't. */
.social-links > .collapse-header {
    display: grid;
    grid-template-columns: auto 60px;
    /* Since clicking anywhere in the header toggles the content, use pointer */
    cursor: pointer;
}

/* Prevent rapidly clicking the header from highlighting it */
.social-links > .collapse-header h1::selection {
    background-color: unset;
}

/* Add padding which fits with the main content for the header ONLY */
.social-links > .collapse-header > div:first-child {
    padding-left: 1em;
    padding-top: 0.25em;
}

/* Have the button stick to the top-right of the Social Links box, make it look
   similar to the wiki search button */
.social-links > .collapse-header > div > button {
    cursor: pointer; /* still needed even though the enclosing div uses it */
    height: 100%;
    display: block;
    width: 100%; /* same width as the column defined in .collapse-header */
    background-color: #222;
    color: white;
    /* Only put a border on the left so the button is flush with the box edge */
    border: 0px;
    border-left: 1px solid white;
    border-radius: 0px;
}

/* When the contents are shown, add a bottom border since the button can't use
   the box's bottom border, and make it 1px taller so it's the same size as when
   it was using the box's bottom border. */
.social-links-show > .collapse-header > div > button {
    height: calc(100% + 1px);
    border-bottom: 1px solid white;
}

/* Since clicking anywhere in the header will toggle the contents, highlight the
   button when the mouse is anywhere in the header. */
.social-links > .collapse-header:hover > div > button {
    background-color: #181818;
    color: #FF4;
}

/* Make the header more compact, and don't give it an underline (the underline
   looks bad when it can't span the whole width due to the button) */
.social-links h1 {
    margin-top: 0px;
    font-size: 1.5em;
    border-bottom: 0px;
}

/* Make level-2 headers more compact as well, but keep the underline since they
   can span the whole width */
.social-links h2 {
    margin-top: 0px;
    font-size: 1.25em;
}

/* Make paragraphs more compact by reducing the spacing */
.social-links > div:last-child > p {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

/* Don't put a margin under the last paragraph, since the div has padding */
.social-links > div:last-child > p:last-child {
    margin-bottom: 0px;
}
