.abilityHighlight {
    color: #FF2;
    font-weight: 400;
}

.ability ul, .ability ol {
    margin: 0px;
}

.ability ol li::marker {
    font-weight: bold;
}

.strictFilter {
    color: #F44;
    font-weight: 500;
}

.ability {
    padding-left: 20px;
}

.ability > h4 {
    position: relative;
    left: -10px;
}

.loadoutDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid white;
    padding-left: 5px;
    padding-right: 5px;
    min-width: 450px;
}

.loadoutDiv:first-child {
    border-top: 0px;
}

.loadoutDiv:nth-child(even) {
    background-color: #111;
    /*
    background-image: linear-gradient(90deg,
        black,
        #111 5px,
        #111 calc(100% - 5px),
        black
    );
    */
}

.loadoutDiv h3 {
    margin-top: 0.5em;
}

.loadoutDiv {
    padding-bottom: 0.5em;
}

.meterArea {
    display: grid;
    grid-template-columns: auto auto auto;
    width: fit-content;
}

.meterGraphic {
    height: 300px;
    width: 18px;
    position: relative;
    top: 8px;
}

.meterSeg {
    width: 18px;
    border: 1px solid white;
    border-left: 2px solid white;
    border-right: 2px solid white;
    box-sizing: border-box;
    background-image: radial-gradient(#FF2, #440)
}

.meterSeg.level1 {
    border-bottom: 2px solid white;
}

.meterSeg.level3 {
    border-top: 2px solid white;
}

.bombLevel {
    width: 16px;
    box-sizing: border-box;
    border: 1px dashed white;
}

.labelArea {
    height: fit-content;
    width: fit-content;
    font-size: 0.8em;
}

.indexLabel {
    margin-right: 3px;
    font-weight: bold;
    text-align: right;
}

.costLabel {
    margin-left: 3px;
}
