/* Containing <div> for the Navbar. */
#navbar {
    /* Stick to top of screen, take up full width */
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    width: 100%;
    /* Solid black background and z-index so it obstructs site contents
    below it */
    z-index: 1;
    /* Clearfix so site contents are displaced by it */
    overflow: auto;

    /* Each item is this tall, looks good w/ the images being 24px tall */
    --item-height: 52px;
}

/* Only child of the #navbar div, contains all of the links. */
#navbar ul {
    background-color: #000;
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    /* Originally this was needed because I didn't use border-box in the
    `#navbar li a` rule below. I'll leave it here in case overflow were to
    occur for any other reason, though. */
    overflow: hidden;
    /* Separate the navbar from the rest of the site */
    border-bottom: 4px solid white;
}

/* Each <li> has an image link to a page on the site */
#navbar li {
    height: var(--item-height);
    /* All links are left-justified except the Steam and Discord links */
    float: left;
    /* Relative so the hover/active overlays can have absolute positioning */
    position: relative;
    /* For hover/active overlay color blending */
    isolation: isolate;
}

/* Steam and Discord links have right float. They're external links so they
look better separated from the others. */
#navbar li.steamLink, #navbar li.discordGIF {
    float: right;
}

/* Make the links take up the full space of the <li>, and add padding so the
contained image is spaced nicely. */
#navbar li a {
    display: block;
    color: white;
    text-align: center;

    /* Make it the same height as the <li>, make sure the padding doesn't get
    added to the height */
    box-sizing: border-box;
    height: 100%;
    padding-top: 14px;
    padding-left: 16px;
    padding-right: 16px;

    text-decoration: none;
    mix-blend-mode: multiply;
}

/* Main links ("HOME", "NEWS", etc) are in an 8x8 font blown up to 24x24 so
there's no blurry antialiasing */
#navbar li a img {
    height: 24px;
}

/* The overlays for active and inactive links are positioned & sized the same */
#navbar .activeOverlay {
    position: absolute;
    height: 100%;
    width: 100%;
    /* The active link is always highlighted, more saturated yellow than
    hovered links */
    background-color: yellow;
}
#navbar .inactiveOverlay {
    position: absolute;
    height: 100%;
    width: 100%;
}

/* Overlay on non-active links, blends its background color w/ white link
on hover */
#navbar li:hover .inactiveOverlay {
    background-color: #FF8;
}

/* The link being blended with the inactiveOverlay in the above rule. If it
doesn't have this black background, the whole thing turns yellow instead of
just the white in the image */
#navbar li a:hover:not(.active) {
    background-color: #000;
}
/* Same reasoning as above, if this rule isn't here the whole square turns
yellow for the active link */
#navbar .active {
    background-color: #000;
}

/* "Buy on Steam" and the Discord link have different sizes than the other
images, so we need special rules to make them fit nicely */
#navbar .steamLink a {
    --steam-padding: 10px;
    padding-top: var(--steam-padding);
}
#navbar .steamLink a img {
    height: calc(var(--item-height) - 2 * var(--steam-padding));
}
#navbar .discordGIF a {
    padding: 0px;
}
#navbar .discordGIF img {
    height: 100%;
}
