#gameSummary {
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
}

#gameSummary img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
}
